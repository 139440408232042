<!--
 * @Description: 创建场景变更服务单
 * @Author: ChenXueLin
 * @Date: 2021-10-14 14:27:46
 * @LastEditTime: 2022-08-25 15:07:04
 * @LastEditors: ChenXueLin
-->
<template>
  <div
    class="content-view-wrapper pd-10"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="content-view">
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">场景变更服务单</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="rules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    placeholder="客户名称"
                    title="客户名称"
                    virtual
                    clearable
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="指派类型" prop="solueIsDept">
                  <el-radio-group v-model="baseInfoForm.solueIsDept">
                    <el-radio :label="1">部门</el-radio>
                    <el-radio :label="0">员工</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="指派部门"
                  prop="departmentId"
                  v-if="baseInfoForm.solueIsDept == 1"
                >
                  <!-- 部门下拉框 -->
                  <e6-select
                    v-model="baseInfoForm.departmentId"
                    :dataList="treeData"
                    :filterable="true"
                    :slotTemplate="'tree'"
                    :multiple="false"
                    :issingleleaf="false"
                    ref="t_select"
                  ></e6-select>
                </el-form-item>
                <el-form-item label="指派处理人" prop="solveRpid" v-else>
                  <all-user-search
                    v-model="baseInfoForm.solveRpid"
                    clear
                    placeholder="指派处理人"
                    title="指派处理人"
                    :propsConfig="{
                      id: 'employeeId',
                      label: 'userName'
                    }"
                  >
                  </all-user-search>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    popper-class="special"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>

                  <!-- <el-button type="primary">
                    导入任务项
                  </el-button> -->
                </div>
              </div>

              <el-table
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in columnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <div
                      v-if="column.fieldName === 'newSceneName'"
                      class="error-content"
                    >
                      <el-input
                        placeholder="新场景名称"
                        v-model="row.newSceneName"
                        @input="
                          row.newSceneName = row.newSceneName.replaceAll(
                            ' ',
                            ''
                          )
                        "
                      ></el-input>
                      <span v-if="row.sceneName == row.newSceneName"
                        >场景名称重复</span
                      >
                    </div>
                    <span v-else>{{ row[column.fieldName] }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
          <!-- 任务项设置   end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 添加任务项弹框 -->
    <select-scene-type
      :corpId="baseInfoForm.corpId"
      @handleClose="handleClose"
      @confirmSelect="confirmSelect"
      :selectSceneDialog="selectSceneDialog"
    ></select-scene-type>
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      @handleClose="handleClose"
      :corpId="baseInfoForm.corpId"
      :corpName="baseInfoForm.corpName"
      @updateList="updateList"
      :isNeedReq="true"
    ></add-concat-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
// getSceneNameList
import { addChangeService } from "@/api";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import { printError } from "@/utils/util";
import addService from "@/mixins/addService";
import addTask from "@/mixins/addTask";
import SelectSceneType from "@/components/workOrderManage/selectSceneType.vue";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "changeScene",
  components: { AddConcatDialog, SelectSceneType, allUserSearch },
  data() {
    return {
      columnData: [
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "原场景名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "newSceneName",
          display: true,
          fieldLabel: "新场景名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selectColumn: [], //选中的数据
      // sceneTypeList: [
      //   { sceneTypeName: "车", sceneType: 1 },
      //   { sceneTypeName: "店", sceneType: 2 },
      //   { sceneTypeName: "仓库", sceneType: 3 }
      // ], //
      addContactVisible: false, //添加联系人弹框
      selectSceneDialog: false
    };
  },
  mixins: [base, addTask, addService],
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //获取场景类型下拉框
    // async getSceneType() {
    //   try {
    //     let res = await getSceneNameList(this.searchForm);
    //     this.sceneTypeList = this.getFreezeResponse(res, {
    //       path: "data"
    //     });
    //   } catch (error) {
    //     printError(error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    },
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid => {
        if (valid) {
          if (!this.tableData.length) {
            this.$message.warning("请至少选择一个任务项");
            return;
          }
          if (
            this.tableData.some(item => {
              return !item.newSceneName;
            })
          ) {
            this.$message.warning("请确认新场景名称是否填写完整");
            return;
          }
          this.baseInfoForm.contactName = this.filterSelect(
            this.contactList,
            "contactId",
            this.baseInfoForm.linkmanrpid
          ).contactName;

          this.addChangeServiceReq();
        }
      });
    },
    //创建场景变更服务单请求
    async addChangeServiceReq() {
      try {
        this.loading = true;
        let res = await addChangeService({
          ...this.baseInfoForm,
          solveRpid:
            this.baseInfoForm.solueIsDept == 1
              ? this.baseInfoForm.departmentId.join("")
              : this.baseInfoForm.solveRpid,
          taskItems: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "workOrderManage/serviceListManage",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    //表格选中数据
    handleSelectionChange(val) {
      this.selectColumn = val;
    },
    /********添加任务项***********/
    //删除任务项
    handleDelete() {
      if (!this.selectColumn.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      let tableData = _.cloneDeep(this.tableData);
      let selectColumn = _.cloneDeep(this.selectColumn);
      this.tableData = tableData.filter(v =>
        selectColumn.every(n => {
          return !(n.sceneId == v.sceneId);
        })
      );
    },
    //点击确定
    confirmSelect(selectData) {
      if (selectData.length) {
        selectData.map(item => {
          item.newSceneName = "";
        });
        this.tableData = _.cloneDeep(selectData).concat(
          _.cloneDeep(this.tableData)
        );
        var obj = {};
        this.tableData = this.tableData.reduce((item, next) => {
          obj[next.sceneId]
            ? ""
            : (obj[next.sceneId] = true && item.push(next));
          return item;
        }, []);
        console.log(this.tableData, "arr==");
      }
    },
    // 关闭弹框
    handleClose(visibleName) {
      this[visibleName] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}

.form-content {
  .el-input {
    width: 280px;
  }
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .operate-action {
    height: 38px;
  }
}

.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.dialog-box .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
/deep/.edit-content .table-select {
  width: 200px;
}
/deep/.error-content {
  display: flex;
  align-items: center;
  & > span {
    color: red;
    margin-left: 8px;
  }
  // .el-input {
  //   width: 260px;
  // }
}
</style>
